import React, { MouseEvent, MutableRefObject} from 'react'
import { Menu } from '@headlessui/react'

import { MenuItem as MenuItemInf} from 'src/packages/lagaat-domain'

import scrollToRef from '../../lib/scrollToRef'

type Close = {
    closePopover?: ( focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null> | undefined ) => void
}

const MenuItem = ({
    anchor, 
    linkRef, 
    href, 
    className, 
    activeClassName,
    closePopover
}: MenuItemInf & Close) => {

    const defaultOnClick = linkRef
        ?   scrollToRef(linkRef)
        :   (e: MouseEvent) => {}

    const onClick = (e: MouseEvent) => {
        if (!linkRef) {
            return
        }
        if (closePopover) {
            closePopover()
        }
        defaultOnClick(e)
    }

    return (
        <Menu.Item>
            {({ active }) => (
                <a
                    className={`${className} ${activeClassName && active && `${activeClassName}`}`}
                    href={href || "#"}
                    onClick={onClick}
                    title={anchor}
                >
                    {anchor}
                </a>
            )}
        </Menu.Item>        
    )
}

export default MenuItem