import {MutableRefObject} from 'react'
import { Menu } from '@headlessui/react'
import tw, { classnames, display, textColor, fontWeight, fontSize, lineHeight, margin, padding, borderWidth } from 'tailwindcss-classnames'

import { Menu as MenuInf } from 'src/packages/lagaat-domain'
import MenuItem from './MenuItem'

type Close = {
    closePopover?: ( focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null> | undefined ) => void
}

const itemStyle = classnames(display("block"), textColor("text-white"), fontWeight("font-light"), fontSize("text-lg"), lineHeight("leading-none"), margin("mb-5", "mr-5", "ml-14"), padding("pb-5"), borderWidth("border-b"))

const itemSmStyle = classnames(margin("sm:mr-6"))

const itemMdStyle = classnames(margin("md:mr-8"))

const itemLgStyle = classnames(display("lg:inline-block"), textColor("lg:text-black"), margin("lg:mb-0", "lg:mr-0", "lg:ml-0"), padding("lg:pb-2", "lg:pl-6"), borderWidth("lg:border-b-0", "lg:border-b"))

const itemXlStyle = classnames(padding("xl:pl-8"))

const item2XlStyle = classnames(padding("2xl:pl-12"))

const BaseMenu = ({closePopover, ...menu}: MenuInf & Close) => {

    return (
        <Menu>
            <>
                <Menu.Items static>

                    {Object.entries(menu).map(([name, menuItem]) => 
                        <MenuItem 
                            key={name} 
                            {...menuItem} 
                            closePopover={closePopover} 
                            className={`last:border-b-0 first:mt-8 lg:hover-bold lg:last:border-b lg:last:text-left lg:last:ml-0 lg:last:pl-0 lg:first:mt-0 ${classnames(itemStyle, itemSmStyle, itemMdStyle, itemLgStyle, itemXlStyle, item2XlStyle)}`} 
                            activeClassName="text-bold"
                        />
                    )}

                </Menu.Items>   

            </>
       
        </Menu>
    )
}

export default BaseMenu